import React from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import {
  BoxBannerDisplaySettingsScheme,
  getBoxBannerContentScheme,
  getBoxBannerContentSettingsScheme,
} from "../../settingsPatterns/boxes/BoxBanner/BoxBanner.scheme";
import { StyledBanner } from "../../settingsPatterns/boxes/BoxBanner/StyledBanner";
import InnerBox from "../../shared/components/InnerBox/InnerBox";
import { IBoxBannerProps } from "./BoxBanner.types";

const BoxBanner: React.FC<IBoxBannerProps> = (props) => {
  const [imgWrapper, imageWidth] = useImageSize();

  const theme = useTheme() as IThemeState;
  const backgroundUrl = theme.advanceSettings.settings.backgroundUrl;

  const content = getBoxBannerContentScheme(backgroundUrl).parse(props.content);

  const contentSettings = getBoxBannerContentSettingsScheme(
    backgroundUrl
  ).parse(props.contentSettings);

  const displaySettings = BoxBannerDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const settings = { ...contentSettings, ...displaySettings };

  return (
    <StyledBanner
      ref={imgWrapper}
      $settings={settings}
      className="banner-container banner-custom-style"
    >
      {settings.image.show && imageWidth && (
        <ImageWrapper
          imageUrl={content.image.imageUrl}
          seoDescription={content.seoDescription}
          imageFit={settings.image.fit}
          imageAlignment={settings.image.alignment}
          opacity={settings.image.opacity}
          width={imageWidth}
          parallax={settings.image.parallax}
          imgTagAsBackground
          link={content.bannerUrl}
        />
      )}
      <InnerBox
        content={content}
        settings={settings}
        className="banner-container__inner-box"
      />
    </StyledBanner>
  );
};

export default withHydrationOnDemand({ on: ["visible"] })(BoxBanner);
